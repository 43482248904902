import React from 'react';

const Surveyform = () => {
  return (
    <div className='survey-form'>
      <div className='form-section'>
      <div>
        <h1>XYZ Group Sustainability Metrics Survey Questionnaire</h1>
        {/* <p>The purpose of this survey is to collect data on your energy consumption, water usage, 
          and recycled plastic raw material percentage to assess your sustainability index. 
          All responses are confidential and will be used internally for the intended purpose only.

          Thank you for your time.
        </p> */}
        <p>*	Required</p>
      </div>
      <div>
        <h2>About Your Company</h2>
        <p1>Your information is highly confidential, the following information is for internal research 
          purpose only, all information will not be shared externally.</p1>
        <br/>
        <br/>
        
        <form>
        <label> 1. Please enter the name of your company * 
          <br/>
        <input type="text" className='text-box'/> </label>
        <br/>

        <label> 2. Please enter your name? *
        <br/>
        <input type="text" className='text-box'/> </label>
        <br/>

        <label> 3.	Please enter your Designation *
        <br/>
        <input type="text" className='text-box'/> </label>
        <br/>

        <label> 4.	Please enter a contact e-mail address *
        <br/>
        <input type="text" className='text-box'/> </label>
        <br/>

        <p className='p-1'>5.  What is your company's annual revenue?</p>
            <div className='radio-field'>
              
                <input type="radio" id="1million_or_less" name="revenue" value="1 Million or less"/>
                <label for="1million_or_less">$1 Million or less</label>
                <br/>
                           
                <input type="radio" id="1to2million" name="revenue" value="$1 Million to $2 Million"/>
                <label for="1to2million">$1 Million to $2 Million</label>
                <br/>
              
                <input type="radio" id="2to3million" name="revenue" value="$2 Million to $3 Million"/>
                <label for="2to3million">$2 Million to $3 Million</label>
                <br/>
                
                <input type="radio" id="3to4million" name="revenue" value="$3 Million to $4 Million"/>
                <label for="3to4million">$3 Million to $4 Million</label>
                <br/>

                <input type="radio" id="4to5million" name="revenue" value="$4 Million to $5 Million"/>
                <label for="4to5million">$4 Million to $5 Million</label>
                <br/>
              
                <input type="radio" id="greater_than_5million" name="revenue" value="Greater than $5 Million"/>
                <label for="greater_than_5million">Greater than $5 Million</label>
                <br/>

                <input type="radio" id="prefer_not_to_say" name="revenue" value="Prefer not to say"/>
                <label for="prefer_not_to_say">Prefer not to say</label>
                <br/>
                
            </div>
            <h3>About your Energy Consumption & Recycled Content</h3>
            {/* <p>Please read following eligible technologies to be considered as renewable electricity (RE) and acknowledge them.
                a.	Wind
                b.	Hydro
                c.	Solar power
                d.	Geothermal
                e.	Solid, liquid, and gaseous forms of Biomass from the fuels (woody waste, land fill gas, wastewater methane, animal & other organic waste, energy crops)
                f.	Ocean-based energy resources captured through tidal and wave technologies. 
                Excluded technologies:
                a.	Electricity from nuclear power is not regarded as renewable electricity.
                b.	Electricity from waste combustion is not regarded as renewable electricity.
            </p>
            <p>
                Definition of Recycled plastics: The process of recovering scrap or waste plastics during the industrial process 
                or after the consumer use and reprocessing the material into useful products, sometimes completely different in form from their original state.
            </p> */}
            <p>6.	Provide your acknowledgement that you have read and understood the procurement mechanisms mentioned above *</p>

                  <div className='radio-field'>
                  <input type="radio" id="I understood" name="understood" value="I understood"/>
                  <label for="I understood">I understood</label>
                  <br/> 
                  <input type="radio" id="I don't understood" name="understood" value="I don't understood"/>
                  <label for="I don't understood">I don't understood</label>
                  <br/>
                  </div>
                  
                
                  
            <p>7.	Does your company has Consumption of purchased or acquired electricity? *</p>
                  <div className='radio-field'>
                  <input type="radio" id="yes" name="consumption" value="Yes"/>
                  <label for="yes">Yes</label>
                  <br/>
                  <input type="radio" id="no" name="consumption" value="No"/>
                  <label for="no">No</label>
                  <br/>
                  </div>

            <p>8.	Do you know how much percentages of Electrical Energy from Renewable/Non-renewable sources (MWh)? *</p>
                  <div className='radio-field'>
                  <input type="radio" id="yes" name="consumption" value="Yes"/>
                  <label for="yes">Yes</label>
                  <br/>
                  <input type="radio" id="no" name="consumption" value="No"/>
                  <label for="no">No</label>
                  <br/>
                  </div>   

            <label> 9.	Please write the Electrical Energy from Renewable sources (MWh)? *
            <br/>
            <input type="text" className='text-box'/> </label>
            <br/>

            <label> 10.	Please write the Electrical Energy from Non-renewable sources (MWh)? *
            <br/>
            <input type="text" className='text-box'/> </label>
            <br/>  


            <div className='radio-field'>
            <p>11.	Does your company have consumption of purchased or acquired heat? *</p>
            <input type="radio" id="yes" name="consumption" value="Yes"/>
            <label for="yes">Yes</label>
            <br/>
            <input type="radio" id="no" name="consumption" value="No"/>
            <label for="no">No</label>
            <br/>  
            </div>

            <label> 12.	Please write the heat Energy acquired or purchased (MWh)? *
            <br/>
            <input type="text" className='text-box'/> </label>
            <br/>  

            <div className='radio-field'>
            <p>13.	Does your company have consumption of purchased or acquired steam? *</p>
            <input type="radio" id="yes" name="consumption" value="Yes"/>
            <label for="yes">Yes</label>
            <br/>
            <input type="radio" id="no" name="consumption" value="No"/>
            <label for="no">No</label>
            <br/>  
            </div>

            <label> 14.	Please write the steam Energy acquired or purchased (MWh)? *
            <br/>
            <input type="text" className='text-box'/> </label>
            <br/>  

            <div className='radio-field'>
            <p>15.	Does your company have consumption of purchased or acquired cooling? *</p>
            <input type="radio" id="yes" name="consumption" value="Yes"/>
            <label for="yes">Yes</label>
            <br/>
            <input type="radio" id="no" name="consumption" value="No"/>
            <label for="no">No</label>
            <br/>  
            </div>

            <label> 16.	Please write the cooling Energy acquired or purchased(MWh)? *
            <br/>
            <input type="text" className='text-box'/> </label>
            <br/>

            <p>17.	Does your company have consumption of self-generated non-fuel renewable energy? *</p>
            <div className='radio-field'>
            <input type="radio" id="yes" name="consumption" value="Yes"/>
            <label for="yes">Yes</label>
            <br/>
            <input type="radio" id="no" name="consumption" value="No"/>
            <label for="no">No</label>
            <br/> 
            </div>

            <label> 18.	Please write the consumption of self-generated non-fuel renewable energy (MWh)? *
            <br/>
            <input type="text" className='text-box'/> </label>
            <br/> 

            <p>19.	Do you use renewable energy in your facility/facilities *</p>
            <div className='radio-field'>
            <input type="radio" id="yes" name="consumption" value="Yes"/>
            <label for="yes">Yes</label>
            <br/>
            <input type="radio" id="no" name="consumption" value="No"/>
            <label for="no">No</label>
            <br/> 
            </div>

            <label> 20. Provide the renewable electricity (RE) usage in percentage out of 100%? *
            <br/>
            <input type="text" className='text-box'/> </label>
            <br/> 

            <p>21.	Does your company has consumption of recycled plastics (thermoplastics, filler & fiber) used in your products*</p>
            <div className='radio-field'>
            <input type="radio" id="yes" name="consumption" value="Yes"/>
            <label for="yes">Yes</label>
            <br/>
            <input type="radio" id="no" name="consumption" value="No"/>
            <label for="no">No</label>
            <br/> 
            </div>

            
            <label> 22.	Please write the percentage of total recycled plastics content used into your production processes
                    Numerical (3 digits) - [Percentage answer between 0%-100%] *
            <br/>
            <input type="text" className='text-box'/> </label>
            <br/> 

            <label> 23.	Could you please share information about your company's current sustainability initiatives and strategies?
              Specifically, we are interested in learning about the actions you are taking to reduce environmental impact, improve energy efficiency, and anything related to promote sustainable practices across your operations. *
            <br/>
            <textarea type="text" className='text-area'/> 
            </label>
            <br/> 
        </form>
      </div>
      <div className='submit-button'> 
        <button className="sub-button">
        Submit
        </button>
        
      </div>
    </div>
  </div>
  )
}

export default Surveyform



