import React from 'react'
import Polymerform from './Pages/Surveyform'

const App = () => {
  return (
    <div>
      <Polymerform/>
    </div>
  )
}

export default App
